import { initSentry } from './sentry';

// Find all tsx files in the web-components directory
// excluding stories files and import them as custom elements
const modules = import.meta.glob([
  './web-components/*.tsx',
  '!./web-components/*.stories.tsx',
]) as Record<string, () => Promise<{ default: CustomElementConstructor }>>;

const registerWebComponents = async () => {
  for (const path in modules) {
    const module = await modules[path]();
    const componentName = (path.split('/').pop() as string).replace('.tsx', '');
    if (customElements.get(componentName) === undefined) {
      customElements.define(componentName, module.default);
    } else {
      console.warn(`Component ${componentName} is already defined`);
    }
  }
};

registerWebComponents();

try {
  initSentry();
} catch (error) {
  console.error('Failed to initialize Sentry:', error);
}
